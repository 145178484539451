<template>
  <div
    id="layout"
    class="van-safe-area-bottom"
    :class="[`page-${String(route.name)}`]"
  >
    <!-- h-full -->
    <div
      id="layoutContainer"
      class="b-0 b-t-1 b-transparent b-solid"
      :class="{ 'layout-mb': useGlobalStore().tabbar }"
    >
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <keep-alive :include="includeList">
            <component :is="Component" :key="route.fullPath" />
          </keep-alive>
        </transition>
      </router-view>
    </div>
  </div>
  <tabbar v-if="useGlobalStore().tabbar"></tabbar>

  <!-- 实名认证弹框 -->
  <a-modal
    v-model:open="useGlobalStore().openReal"
    :maskClosable="false"
    title=""
    width="680px"
    :footer="null"
    :destroyOnClose="true"
    :centered="true"
    class="myModel"
    :zIndex="10"
  >
    <template #closeIcon>
      <img src="@/assets/images/common/close.png" alt="close" />
    </template>
    <realName></realName>
  </a-modal>

  <search
    v-if="useGlobalStore().showSearch"
    @close="useGlobalStore().setShowSearch(false)"
  ></search>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import tabbar from "@/components/common/tabbar.vue";
import { useGlobalStore } from "@/store/modules/global";
import { useUserStore } from "@/store/modules/user";
// import { setToastDefaultOptions } from "vant";
import { QueryString, uniPostMessage } from "@/utils/Util";
import { apiDict, apiTag } from "@/api";
import { usePreloadStore } from "@/store/modules/preload";
import realName from "@/components/common/realName.vue";
import search from "@/components/common/search.vue";

const route = useRoute();
const router = useRouter();
const includeList = ref<string[]>([]);
// 路由缓存
watch(route, (to) => {
  if (to.meta.keepAlive && includeList.value.indexOf(String(to.name)) === -1) {
    includeList.value.push(String(to.name));
  }
});

(window as any).uni_onBackPress = () => {
  router.go(-1);
  return true;
};

try {
  setTimeout(() => {
    useUserStore().autoLogin();
  }, 1000);
} catch (error) {
  console.log("autoLogin error:", error);
}

// 获取tag
apiTag().then((res: any) => {
  usePreloadStore().setTagList(res.data);
});

// 获取区服
apiDict("region").then((res: any) => {
  usePreloadStore().setRegionList(res.region);
});

onMounted(() => {
  nextTick(() => {
    document.addEventListener("UniAppJSBridgeReady", function () {
      console.log("UniAppJSBridgeReady");
      (window as any).uni.webView.getEnv((res: any) => {
        console.log("getEnv", res);
        if (res.plus) {
          useGlobalStore().setUapp(true);
          uniPostMessage("uni_getSystemInfoSync").then((res: any) => {
            console.log("uni_getSystemInfoSync", res);
          });
          uniPostMessage("uni_getChannel").then((res: any) => {
            console.log("uni_getChannel", res);
            if (res) {
              localStorage.setItem("channel", res);
            }
          });
        }
      });
    });
  });
});

applicationHandle();

function applicationHandle() {
  const channel = QueryString("channel");
  channel && localStorage.setItem("channel", channel);
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 28px;
  color: #333;
}

#layout {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.myModel {
  .ant-modal-content {
    padding: 0;
    background: transparent !important;
  }
}

.van-image__error,
.van-image__loading {
  background: #323237 !important;
  border-radius: 12px;
}

.layout-mb {
  padding-bottom: 140px;
}

.my-popup {
  background: none;
}

.bg-tankuang {
  background: url("@/assets/images/common/bg-tankuang.jpg") no-repeat center
    center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

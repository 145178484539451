<template>
  <div class="flex justify-between items-center h-128">
    <div class="flex items-center relative">
      <div
        class="absolute top-8 left-8 pl-6 pr-10 h-32 bg-#333 bg-op-60 rd-8 fac p-4"
      >
        <platformIcon
          :platform="item.game_platform"
          class="w-32 h-32"
        ></platformIcon>
        <span class="c-#fff text-18 ml-4">{{
          useRegion().Region[item.region]
        }}</span>
      </div>
      <div
        class="absolute top-0 left-176 w-72 h-32 bg-#333 rd-[0_8px_0_8px] fc c-#fff text-18"
      >
        -{{ discounts(Number(tag.price)) }}%
      </div>
      <img
        class="w-248 h-128 object-cover rd-8 mr-12"
        :src="item.game_product.list_img"
        alt="img"
      />
      <div class="flex flex-col c-#333">
        <div class="mb-10 w-440 text-24 font-bold ellipsis mb-20">
          {{ item.game_name }}
        </div>
        <div class="fac">
          <div class="w-250 h-40 fac bg-#333 bg-op-8 rd-4 relative gap-8 pl-10">
            <span class="text-20">{{ tag.danwei }}</span>
            <span class="text-24">{{ tag.price }} </span>
            <del class="text-20 c-#333 c-op-40">{{ item.source_price }}</del>
            <div
              class="absolute right--40 w-80 h-40 fc text-20 c-#fff pl-10"
              :class="`tag-${tag.type}`"
            >
              {{ tag.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRegion } from "@/hooks/useRegion";

const props = defineProps<{
  item: ProductDetail;
}>();

const tag = computed(() => {
  const item: any = props.item;
  let price = "";
  let danwei = "CNY";
  let name = "";
  let type = 1;

  if (item) {
    if (item.support_account) {
      price = item.account_price;
      name = "新号";
      type = 1;
    } else if (item.support_cdk) {
      price = item.cdk_price;
      name = "CDK";
      type = 3;
    } else {
      price = item.depot_account_price;
      danwei = "积分";
      name = "二手";
      type = 2;
    }
  }
  return {
    price,
    danwei,
    name,
    type,
  };
});

const discounts = (num: number) => {
  const price = Number(props.item.source_price);
  return ~~((1 - num / price) * 100);
};
</script>

<style lang="scss" scoped>
.tag-1 {
  background: url("@/assets/images/common/tag-1.png");
  background-size: 100% 100%;
}
.tag-2 {
  background: url("@/assets/images/common/tag-2.png");
  background-size: 100% 100%;
}
.tag-3 {
  background: url("@/assets/images/common/tag-3.png");
  background-size: 100% 100%;
}
</style>

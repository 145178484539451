import { apiGame } from "@/api";
import { defineStore } from "pinia";

interface IGlobalState {
  tabbar: boolean;
  savedPosition: any;
  uapp: boolean;
  gameList: ProductDetail[];
  gameListTotal: number;
  filterParams: IGameParams;
  openReal: boolean;
  archiveModItem: any;
  showSearch: boolean;
}

export const useGlobalStore = defineStore("app-globalStore", {
  state: (): IGlobalState => ({
    tabbar: true,
    savedPosition: {},
    uapp: false,
    openReal: false,
    filterParams: {
      page: 1,
      page_size: 10,
      sort: "hot",
    },
    gameList: [],
    gameListTotal: 0,
    archiveModItem: {},
    showSearch: false,
  }),
  persist: {
    key: "steampay-app-globalStore",
    storage: sessionStorage,
  },
  actions: {
    setShowSearch(value: boolean) {
      this.showSearch = value;
    },
    setArchiveModItem(item: any) {
      this.archiveModItem = item;
    },
    setRealName(value: boolean) {
      this.openReal = value;
    },
    setUapp(v: boolean) {
      this.uapp = v;
    },
    setTabbar(value: boolean) {
      this.tabbar = value;
    },
    setSavedPosition(key: string, value: number) {
      this.savedPosition[key] = value;
    },
    initFilterParams() {
      this.filterParams = {
        page: 1,
        page_size: 10,
        sort: "hot",
      };
      this.gameList = [];
      this.gameListTotal = 0;
    },
    setFilterParams(obj: IGameParams) {
      return new Promise((resolve, reject) => {
        this.filterParams = { ...this.filterParams, ...obj };
        // console.log("this.filterParams", this.filterParams);
        apiGame(this.filterParams).then((res: IGame) => {
          this.gameList = [...this.gameList, ...res.data];
          this.gameListTotal = res.total;
          if (res.data.length > 0) {
            resolve(null);
          } else {
            reject();
          }
        });
      });
    },
  },
});
